import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";
import PropTypes from "prop-types";
import { useFiles } from "../../../context/files";
import {
  Col,
  Row,
  Card,
  Table
} from "react-bootstrap";



const UploadFile = props => {
  const {
    disabled,
    ...attributes
  } = props;

  const { updateFile, deleteFile, uploadedFiles } = useFiles();
  const apiUrl = process.env.NODE_ENV === "production" ? "https://api.appcardiosis.com.br" : "https://api.cfnk.com.br";
  const fileUrl = process.env.NODE_ENV === "production" ? "https://leccardiosis.nyc3.digitaloceanspaces.com/uploads" : "https://api.cfnk.com.br";

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    const accessToken = localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxTk") : sessionStorage.getItem("xLcxTk");
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };

    return { url: `${apiUrl}/files`, headers };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr, remove }, status) => {
    if (status === "done") {
      const incomingFiles = JSON.parse(xhr.response);

      incomingFiles.forEach((file) => {
        file.fileId = meta.key;
        updateFile(file);
      });
      remove()
    }
  };

  return (
    <>
      <Drop
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputContent="Clique ou arraste os arquivos aqui"
        canCancel={false}
        styles={{
          dropzone: {
            minHeight: 200,
            maxHeight: 250,
            width: "100%",
            backgroundColor: "#f2f4fa",
            border: "1px dashed #DDDFE1",
            overflow: "hidden",
          },
          inputLabel: {
            color: "#7e7e7e",
            fontSize: "16px",
            fontWeight: "normal",
            backgroundColor: "#f2f4fa",
          },
        }}
        disabled={disabled}
      />
      <br />
      <Row hidden={uploadedFiles.length === 0}>
        <h4>Clique no arquivo para abrir</h4>
        <Col lg={12}>
          <Card>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Arquivos</strong>
                  </th>
                  <th>
                    <strong>Ações</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  uploadedFiles.map((file) => {
                    if (!file.isDeleted) {
                      return (
                        <tr key={file.fileId}>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href={`${fileUrl}/${file.name}`} target="_blank" rel="noreferrer" download>
                                <span className="w-space-no">{file.originalName}</span>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn btn-primary shadow sharp ms-1"
                                onClick={() => deleteFile(file.fileId)}
                                disabled={disabled}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    }
                  })
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>

  );
};

UploadFile.propTypes = {
  disabled: PropTypes.bool
};

UploadFile.defaultProps = {
  disabled: false
};

export default UploadFile;